/**
 * Created by fulle on 2023/03/01.
 */
import moment from "moment"

const isValidDate = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length > 0)
        return moment(value).isValid()

    return true
}

const isValidPhone = (_value) =>
{
    if(typeof _value !== "string")
        return false;
    return /^[+1-9][0-9]{6,14}$/.test(_value.replace(/\)|\(| /gi, ""));
}

const isValidOmang = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length === 9)
    {
        return (["1","2"].indexOf(value[4].toString()) >= 0)
    }

    return false
}

const itemExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null
}

const itemListExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null && _value.length > 0
}

const textExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null && _value.length > 0
}

const getGenderFromOmang = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length === 9)
    {
        if(value[4].toString() == "1")
            return "Male"
        else if(value[4].toString() == "2")
            return "Female"

    }

    return null
}

const isNumeric = (_value) => {
    return !isNaN(parseFloat(_value)) && isFinite(_value);
}

const getStatusBGColor = (_status) => {
    let _color = ""
    if(itemExists(_status))
    {
        if(_status.toUpperCase() === "INITIATED")
            _color = "orange lighten-1"
        else if(_status.toUpperCase() === "CONFIRMED")
            _color = "green accent-1"
        else if(_status.toUpperCase() === "REJECTED")
            _color = "red accent-2"
        else if(["COMPLETE", "COMPLETED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "deep-purple accent-2"
        else if(["SUBMITTED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "blue lighten-5"
        else if(["REFUNDABLE"].indexOf(_status.toUpperCase()) >= 0)
            _color = "cyan lighten-3"
        else if(["REFUNDED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "cyan accent-4"
        else if(["EXTENDABLE"].indexOf(_status.toUpperCase()) >= 0)
            _color = "lime lighten-2"
        else if(["EXTENDED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "lime accent-4"
        else if(["SUBMITTED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "blue lighten-5"
    }
    return _color
}

const isValidContactNumber = (_value) => {
    return /^\+\d{6,15}$/.test(_value)
};

const isValidGeoLocation = (_lat, _long) => {
    if(!itemExists(_lat) || !itemExists(_long) || !isNumeric(_lat) || !isNumeric(_long))
        return false;

    if(parseFloat(_lat) < -90 || parseFloat(_lat) > 90)
        return false;

    if(parseFloat(_lat) < -180 || parseFloat(_lat) > 180)
        return false;

    return true;
};

const getGMapsUrl = (_lat, _long) => {
    /*https://developers.google.com/maps/documentation/urls/get-started*/
    if(!isValidGeoLocation(_lat, _long))
        return null;

    return "https://www.google.com/maps/search/?api=1&query="+_lat+"%2C"+_long;
};

export { isValidDate, isValidPhone, isValidOmang, getGenderFromOmang, itemExists, textExists, itemListExists, getStatusBGColor, isNumeric, isValidGeoLocation, getGMapsUrl }