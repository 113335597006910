<template>
	
	<!-- Layout Footer -->
	<a-layout-footer class="footer-bg">
		<a-row type="flex">
			<a-col :span="24" :md="12">

				<!-- Footer Copyright Notice -->
				<p class="copyright font-9">
					© {{new Date().getFullYear()}} All rights Reserved <span class="0">to <a :href="links.company" target="_blank">{{contact_company}}</a></span>

				</p>
				<!-- / Footer Copyright Notice -->

			</a-col>
			<a-col :span="24" :md="12" class="footer-control">

				<!-- Footer Navigation Menu -->
				<a-menu mode="horizontal">
					<a-menu-item><a :href="links.privacy_policy" target="_blank" class="font-9 adbox-gold-text">
						Privacy Policy
					</a></a-menu-item>
					<a-menu-item><a :href="links.facebook" target="_blank">
						<img src="images/icons/icon-fb.png" width="20px" alt="facebook" />
					</a></a-menu-item>
				</a-menu>
				<!-- / Footer Navigation Menu -->

			</a-col>
		</a-row>
	</a-layout-footer>
	<!-- / Layout Footer -->

</template>

<script>
    import CUSTOM_CONSTANTS from "@/constants";
	export default ({
		data() {
			return {
                contact_number: CUSTOM_CONSTANTS.contact.phone,
                contact_email: CUSTOM_CONSTANTS.contact.email,
                contact_company: CUSTOM_CONSTANTS.contact.company,
				links: {
                    privacy_policy: CUSTOM_CONSTANTS.links.privacy_policy,
                    facebook: CUSTOM_CONSTANTS.links.facebook,
                    company: CUSTOM_CONSTANTS.links.contact,
				}

			}
		},
	})

</script>
<style>
	.layout-dashboard .footer-bg.ant-layout-footer {
		background-color: #373737 !important;
	}
	.layout-dashboard .footer-bg.ant-layout-footer .copyright {
		color: white;
	}
	.layout-dashboard .footer-bg.ant-layout-footer .copyright a
	{
		color: #d1b82c;
	}
	.layout-dashboard .footer-bg.ant-layout-footer .footer-control .ant-menu-horizontal > .ant-menu-item > a
	{
		color: white;
	}
</style>
