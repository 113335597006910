/**
 * Created by fulle on 2023/02/06.
 */
const CUSTOM_CONSTANTS = {
    access_role: [
        "super-administrator",
        "system-administrator",
        "manager",
        "auditor",
        "agent",
        "account"
    ],
    contact: {
        email: "info@adbox.africa",
        phone: "+3930332",
        company: "BrandBox Botswana"
    },
    links: {
        contact: "https://brandboxbw.com",
        privacy_policy: "https://brandboxbw.com/privacy-policy",
        facebook: "https://www.facebook.com/brandboxbw"
    },
    size: {
        default: {
            membership_number: 6,
            policy_number: 6,
            email: 4,
            mobile: 8,
            name: 2,
            password: 8,
            pin: 4,
            phone: 6,
            otp: 6,
            image: {
                height: 512,
                width: 512
            }
        },
        min: {
            id_number: 6,
            code: 6,
            membership_number: 6,
            policy_number: 4,
            description: 3,
            email: 4,
            mobile: 8,
            name: 2,
            password: 8,
            pin: 4,
            phone: 6,
            otp: 4,
            image: {
                height: 512,
                width: 512
            },
            price: 150
        },
        max: {
            nature: 50,
            membership_number: 32,
            id_number: 32,
            code: 12,
            policy_number: 32,
            comment: 250,
            email: 150,
            mobile: 20,
            name: 150,
            password: 50,
            pin: 12,
            phone: 12,
            otp: 20,
            image: {
                height: 1920,
                width: 1920
            },
            address: 250
        }
    },
    date: {
        format: {
            default: "MMM Do YY",
            full: " YY, MMM Do @HH:mm",
            date: " DD/MM/YYYY",
            full_date: " DD/MM/YYYY @HH:mm",
        },
        year_limit: 1970
    },
    retirement_age: 65,
    payrollGroups: [
        {
            name: "BTU Member",
            label: "BTU Membership #",
        },
        {
            name: "MPI Employee",
            label: "MPI Employee #",
        }
    ],
    schemes: {
        subpolicy: ["BOTUBS"],
        titles: {
            botuff: "BOTUFF",
            botubs: "BOTUBS",
            poloko: "POLOKO",
        }
    },
    subpolicies: [
        {
            package: "BRONZE",
            scheme: "BOTUBS"
        }
    ],
    quill: {
        options: {
            modules: {
                toolbar: [
                    [{ 'header': [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'link'],
                    ['clean']
                ]
            },
        }
    },
    tinymce: {
        plugins: 'table advlist lists image media anchor link autoresize help wordcount',
        toolbar: 'blocks bold forecolor backcolor | bullist numlist | link image media anchor | table',
    },
    // use the following for demo data you need in UI design
    demoData:{
        user:{
            "id": 14,
            "reference": "6Rpy0mjbWoObEQjgh4D6UgKXUFl1",
            "firstname": "Testing",
            "lastname": "Tester",
            "emailAddress": "tester@gmail.com",
            "createdBy": "-1",
            "dateCreated": "2023-02-11T11:44:06.000Z",
            "lastUpdatedBy": "-1",
            "lastUpdated": "2023-02-11T11:44:06.000Z",
            "active": true,
            "published": false,
            "contactNumber": "75960176",
            "region": "",
            "approved": false,
            "approvedBy": 0,
            "approvedAt": null,
            "school": "",
            "identificationNumber": "",
            "dateOfBirth": null,
            "occupation": "",
            "membershipNumber": "19019980",
            "nationality": "",
            "gender": "",
            "payrollNumber": "",
            "deductionSource": "",
            "sector": "",
            "district": "",
            "kyc": {
                "id": 2
            },
            "dependents": [
                {
                    "id": 2,
                    "identityNumber": "4522552124",
                    "firstName": "Lil Baby",
                    "middleName": null,
                    "surname": "Doe",
                    "dateOfBirth": "1988-04-01T22:00:00.000Z",
                    "gender": "Male",
                    "type": "Child",
                    "relationship": null,
                    "contactNumber": "+267 75000119",
                    "employmentStatus": "Permanent & Pensionable",
                    "maritalStatus": "Single",
                    "nextOfKin": null,
                    "numberOfDependents": null,
                    "lastUpdatedBy": 14,
                    "lastUpdated": "2023-02-13T23:43:44.000Z"
                }
            ],
            "spouse": null,
            "payrollGroup": {
                "id": 1,
                "name": "BTU Member"
            }
        },
        kyc:{
            id: 2,
            identityInfo: {
                "id": 2,
                "idNumber": "454415310",
                "type": "Omang",
                "fileId": 10
            },
            addressInfo: {
                "id": 3,
                "plotNo": "24423",
                "streetName": "Omang",
                "ward": "Ext 9.",
                "city": "Gaborone",
                "district": "South-East",
                "type": "Rental",
                "fileId": 9
            },
            employmentInfo: {
                "id": 2,
                "employerName": "Benthema Primary School",
                "position": "Teacher",
                "employerContact": "+390 303030",
                "departmentName": "English",
                "employeeNumber": "EMP99121",
                "payrollId": "PO00012",
                "basicSalary": 12000,
                "yearStart": null,
                "yearEnded": null
            },
            bankAccounts: [
                {
                    "id": 1,
                    "bankName": "First National Bank Botswana",
                    "branchName": "Main Mall",
                    "branchCode": "120004",
                    "accountNumber": "0938208023498",
                    "bankStatementFileId": -1
                }
            ]
        },
        funeralSchemes: [
            {
                "id": 1,
                "published": true,
                "createdBy": "0",
                "dateCreated": "2023-02-13T10:36:00.000Z",
                "lastUpdatedBy": "0",
                "lastUpdated": "2023-02-13T10:36:00.000Z",
                "title": "POLOKO",
                "description": "Poloko is a scheme is a scheme providing services of funeral & undertaking(through country-wide network of parlours), supply of domes/caskets for its members according to the preferred package, mortuary services (accommodation, care and preparation of body) and set-up at the cemetery.",
                "imageUrl": "https://mpibtu.com/wp-content/uploads/2020/10/Poloko-Logo-768x293.png",
                "category": "Funeral",
                "packages": [
                    {
                        "id": 178,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Letshegedi",
                        "description": "P20 000 Main Member Payout",
                        "premium": 27,
                        "addOns": [
                            {
                                "id": 454,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Letshegedi - For Parents & Parents-In-Law",
                                "premium": 50,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            },
                            {
                                "id": 456,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (<=30 years)",
                                "premium": 10,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 457,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (31-40 years)",
                                "premium": 28,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 458,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (41-50 years)",
                                "premium": 34,
                                "minAgeRule": 41,
                                "maxAgeRule": 50,
                                "type": "Age Based"
                            },
                            {
                                "id": 459,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (51-60 years)",
                                "premium": 41,
                                "minAgeRule": 51,
                                "maxAgeRule": 60,
                                "type": "Age Based"
                            },
                            {
                                "id": 460,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (61-65 years)",
                                "premium": 54,
                                "minAgeRule": 61,
                                "maxAgeRule": 65,
                                "type": "Age Based"
                            },
                            {
                                "id": 461,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (66-70 years)",
                                "premium": 65,
                                "minAgeRule": 66,
                                "maxAgeRule": 70,
                                "type": "Age Based"
                            },
                            {
                                "id": 462,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (71-75 years)",
                                "premium": 97,
                                "minAgeRule": 71,
                                "maxAgeRule": 75,
                                "type": "Age Based"
                            },
                            {
                                "id": 463,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (76-80 years)",
                                "premium": 135,
                                "minAgeRule": 76,
                                "maxAgeRule": 80,
                                "type": "Age Based"
                            },
                            {
                                "id": 464,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (81-85 years)",
                                "premium": 135,
                                "minAgeRule": 81,
                                "maxAgeRule": 85,
                                "type": "Age Based"
                            }
                        ]
                    },
                    {
                        "id": 179,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Lesedi",
                        "description": "P25 000 Main Member Payout",
                        "premium": 28,
                        "addOns": [
                            {
                                "id": 455,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Lesedi - For Parents & Parents-In-Law",
                                "premium": 64,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            },
                            {
                                "id": 456,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (<=30 years)",
                                "premium": 10,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 457,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (31-40 years)",
                                "premium": 28,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 458,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (41-50 years)",
                                "premium": 34,
                                "minAgeRule": 41,
                                "maxAgeRule": 50,
                                "type": "Age Based"
                            },
                            {
                                "id": 459,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (51-60 years)",
                                "premium": 41,
                                "minAgeRule": 51,
                                "maxAgeRule": 60,
                                "type": "Age Based"
                            },
                            {
                                "id": 460,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (61-65 years)",
                                "premium": 54,
                                "minAgeRule": 61,
                                "maxAgeRule": 65,
                                "type": "Age Based"
                            },
                            {
                                "id": 461,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (66-70 years)",
                                "premium": 65,
                                "minAgeRule": 66,
                                "maxAgeRule": 70,
                                "type": "Age Based"
                            },
                            {
                                "id": 462,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (71-75 years)",
                                "premium": 97,
                                "minAgeRule": 71,
                                "maxAgeRule": 75,
                                "type": "Age Based"
                            },
                            {
                                "id": 463,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (76-80 years)",
                                "premium": 135,
                                "minAgeRule": 76,
                                "maxAgeRule": 80,
                                "type": "Age Based"
                            },
                            {
                                "id": 464,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (81-85 years)",
                                "premium": 135,
                                "minAgeRule": 81,
                                "maxAgeRule": 85,
                                "type": "Age Based"
                            }
                        ]
                    },
                    {
                        "id": 180,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Lebone",
                        "description": "P30 000 Main Member Payout",
                        "premium": 30,
                        "addOns": [
                            {
                                "id": 456,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (<=30 years)",
                                "premium": 10,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 457,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (31-40 years)",
                                "premium": 28,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 458,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (41-50 years)",
                                "premium": 34,
                                "minAgeRule": 41,
                                "maxAgeRule": 50,
                                "type": "Age Based"
                            },
                            {
                                "id": 459,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (51-60 years)",
                                "premium": 41,
                                "minAgeRule": 51,
                                "maxAgeRule": 60,
                                "type": "Age Based"
                            },
                            {
                                "id": 460,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (61-65 years)",
                                "premium": 54,
                                "minAgeRule": 61,
                                "maxAgeRule": 65,
                                "type": "Age Based"
                            },
                            {
                                "id": 461,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (66-70 years)",
                                "premium": 65,
                                "minAgeRule": 66,
                                "maxAgeRule": 70,
                                "type": "Age Based"
                            },
                            {
                                "id": 462,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (71-75 years)",
                                "premium": 97,
                                "minAgeRule": 71,
                                "maxAgeRule": 75,
                                "type": "Age Based"
                            },
                            {
                                "id": 463,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (76-80 years)",
                                "premium": 135,
                                "minAgeRule": 76,
                                "maxAgeRule": 80,
                                "type": "Age Based"
                            },
                            {
                                "id": 464,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (81-85 years)",
                                "premium": 135,
                                "minAgeRule": 81,
                                "maxAgeRule": 85,
                                "type": "Age Based"
                            }
                        ]
                    },
                    {
                        "id": 181,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Lerato",
                        "description": "P40 000 Main Member Payout",
                        "premium": 38,
                        "addOns": [
                            {
                                "id": 456,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (<=30 years)",
                                "premium": 10,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 457,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (31-40 years)",
                                "premium": 28,
                                "minAgeRule": 0,
                                "maxAgeRule": 30,
                                "type": "Age Based"
                            },
                            {
                                "id": 458,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (41-50 years)",
                                "premium": 34,
                                "minAgeRule": 41,
                                "maxAgeRule": 50,
                                "type": "Age Based"
                            },
                            {
                                "id": 459,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (51-60 years)",
                                "premium": 41,
                                "minAgeRule": 51,
                                "maxAgeRule": 60,
                                "type": "Age Based"
                            },
                            {
                                "id": 460,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (61-65 years)",
                                "premium": 54,
                                "minAgeRule": 61,
                                "maxAgeRule": 65,
                                "type": "Age Based"
                            },
                            {
                                "id": 461,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (66-70 years)",
                                "premium": 65,
                                "minAgeRule": 66,
                                "maxAgeRule": 70,
                                "type": "Age Based"
                            },
                            {
                                "id": 462,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (71-75 years)",
                                "premium": 97,
                                "minAgeRule": 71,
                                "maxAgeRule": 75,
                                "type": "Age Based"
                            },
                            {
                                "id": 463,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (76-80 years)",
                                "premium": 135,
                                "minAgeRule": 76,
                                "maxAgeRule": 80,
                                "type": "Age Based"
                            },
                            {
                                "id": 464,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "For Extended Family (81-85 years)",
                                "premium": 135,
                                "minAgeRule": 81,
                                "maxAgeRule": 85,
                                "type": "Age Based"
                            }
                        ]
                    }
                ]
            },
            {
                "id": 2,
                "published": true,
                "createdBy": "0",
                "dateCreated": "2023-02-13T10:36:00.000Z",
                "lastUpdatedBy": "0",
                "lastUpdated": "2023-02-13T10:36:00.000Z",
                "title": "BOTUBS",
                "description": "BOTUBS (Botswana Teachers Union Members Burial Society) is a  group funeral scheme underwritten by Hollard Insurance.",
                "imageUrl": "https://mpibtu.com/wp-content/uploads/2020/10/Burial-Society.png",
                "category": "Funeral",
                "packages": [
                    {
                        "id": 182,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Bronze",
                        "description": "P30 000 Main Member Payout",
                        "premium": 45,
                        "addOns": [
                            {
                                "id": 465,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Extended Family (Bronze Cover)",
                                "premium": 30,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            },
                            {
                                "id": 466,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Parents/Parents-in-Law (Bronze Cover)",
                                "premium": 25,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            }
                        ]
                    },
                    {
                        "id": 183,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Silver",
                        "description": "P45 000 Main Member/Spouse Payout",
                        "premium": 60,
                        "addOns": [
                            {
                                "id": 467,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Extended Family (Silver Cover)",
                                "premium": 55,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            },
                            {
                                "id": 468,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Parents/Parents-in-Law (Silver Cover)",
                                "premium": 50,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            }
                        ]
                    },
                    {
                        "id": 184,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Gold",
                        "description": "P55 000 Main Member/Spouse Payout",
                        "premium": 65,
                        "addOns": [
                            {
                                "id": 469,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Extended Family (Gold Cover)",
                                "premium": 55,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            },
                            {
                                "id": 470,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Parents/Parents-in-Law (Gold Cover)",
                                "premium": 50,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            }
                        ]
                    },
                    {
                        "id": 185,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "Diamond",
                        "description": "P65 000 Main Member/Spouse Payout",
                        "premium": 70,
                        "addOns": [
                            {
                                "id": 471,
                                "published": true,
                                "createdBy": "0",
                                "dateCreated": "2023-02-13T10:36:00.000Z",
                                "lastUpdatedBy": "0",
                                "lastUpdated": "2023-02-13T10:36:00.000Z",
                                "name": "Parents/Parents-in-Law (Diamond Cover)",
                                "premium": 80,
                                "minAgeRule": 0,
                                "maxAgeRule": 0,
                                "type": "Fixed Price"
                            }
                        ]
                    }
                ]
            }
        ],
        applications: [
            {
                "id": 2,
                "published": true,
                "reference": "111111111111",
                "createdBy": "0",
                "status" : "PENDING",
                "deductionAmount": "250",
                "dateCreated": "2023-02-13T10:36:00.000Z",
                "lastUpdatedBy": "0",
                "lastUpdated": "2023-02-13T10:36:00.000Z",
                "client":{
                    "id": 14,
                    "reference": "6Rpy0mjbWoObEQjgh4D6UgKXUFl1",
                    "firstname": "Testing",
                    "lastname": "Tester",
                    "emailAddress": "tester@gmail.com",
                    "createdBy": "-1",
                    "dateCreated": "2023-02-11T11:44:06.000Z",
                    "lastUpdatedBy": "-1",
                    "lastUpdated": "2023-02-11T11:44:06.000Z",
                    "active": true,
                    "published": false,
                    "contactNumber": "75960176",
                    "region": "",
                    "approved": false,
                    "approvedBy": 0,
                    "approvedAt": null,
                    "school": "",
                    "identificationNumber": "",
                    "dateOfBirth": null,
                    "occupation": "",
                    "membershipNumber": "19019980",
                    "nationality": "",
                    "gender": "",
                    "payrollNumber": "",
                    "deductionSource": "",
                    "sector": "",
                    "district": "",
                    "applications": [],
                    "documents": [
                        {
                            "id": 10,
                            "lastUpdatedBy": 10,
                            "documentUrl": "https://storage.googleapis.com/mpi-backend-dev.appspot.com/MmWkmbBDCKXvR1A4sLJOtDU5heh1/uploads/1676213480135/Exclusive.drawio?GoogleAccessId=firebase-adminsdk-sz7fx%40mpi-backend-dev.iam.gserviceaccount.com&Expires=16447017600&Signature=BWZ6laeqxcFqr6o%2BQ3cwLGGen7CDEev%2BLCMzCBRRJLr2vEWogkw1vhtURUtNVRc2la6YOIE87GDf%2FXlwZheso00ZtIPdPnlbKkQ%2FLwRg3xHECtJj5oaslOfvfr%2FSO3MesveHkGNmoCV9EozBiFZ7VSSrpN3utVby2UdUMEERGkxzPh7sRbmHeKWg0qj0e2bTCb%2FezkFK9eD%2Bh5jwAKkGIPjzPthtO4K0ErWGUDg17FN2MJit628QPydPr0b7xOUz%2FpewMzbnXMBMBpp9Eb6kE80SEcelK0V%2FgqwYfv0HKm%2BizSjaUA%2BF%2FPs5ovm3W66Tz2U4rRAmdUOMe%2FT88bg24w%3D%3D",
                            "storageRef": "MmWkmbBDCKXvR1A4sLJOtDU5heh1/uploads/1676213480135/Exclusive.drawio",
                            "verified": false,
                            "verifiedBy": 0,
                            "verifiedAt": null,
                            "lastUpdated": null
                        }
                    ],
                    "kyc": {
                        "id": 2,
                        "identityInfo": {
                            "id": 2,
                            "idNumber": "454415310",
                            "type": "Omang",
                            "fileId": 10
                        },
                        "addressInfo": {
                            "id": 3,
                            "plotNo": "24423",
                            "streetName": "Omang",
                            "ward": "Ext 9.",
                            "city": "Gaborone",
                            "district": "South-East",
                            "type": "Rental",
                            "fileId": 9
                        },
                        "employmentInfo": {
                            "id": 2,
                            "employerName": "Benthema Primary School",
                            "position": "Teacher",
                            "employerContact": "+390 303030",
                            "departmentName": "English",
                            "employeeNumber": "EMP99121",
                            "payrollId": "PO00012",
                            "basicSalary": 12000,
                            "yearStart": null,
                            "yearEnded": null
                        },
                        "bankAccounts": [
                            {
                                "id": 1,
                                "bankName": "First National Bank Botswana",
                                "branchName": "Main Mall",
                                "branchCode": "120004",
                                "accountNumber": "0938208023498",
                                "bankStatementFileId": -1
                            }
                        ]
                    },
                    "dependents": [
                        {
                            "id": 2,
                            "identityNumber": "4522552124",
                            "firstName": "Lil Baby",
                            "middleName": null,
                            "surname": "Doe",
                            "dateOfBirth": "1988-04-01T22:00:00.000Z",
                            "gender": "Male",
                            "type": "Child",
                            "relationship": null,
                            "contactNumber": "+267 75000119",
                            "employmentStatus": "Permanent & Pensionable",
                            "maritalStatus": "Single",
                            "nextOfKin": null,
                            "numberOfDependents": null,
                            "lastUpdatedBy": 14,
                            "lastUpdated": "2023-02-13T23:43:44.000Z"
                        }
                    ],
                    "spouse": null,
                    "payrollGroup": {
                        "id": 1,
                        "name": "BTU Member"
                    }
                },
                "package":{
                    "id": 184,
                    "published": true,
                    "createdBy": "0",
                    "dateCreated": "2023-02-13T10:36:00.000Z",
                    "lastUpdatedBy": "0",
                    "lastUpdated": "2023-02-13T10:36:00.000Z",
                    "title": "Gold",
                    "description": "P55 000 Main Member/Spouse Payout",
                    "premium": 65,
                    "scheme" : {
                        "id": 2,
                        "published": true,
                        "createdBy": "0",
                        "dateCreated": "2023-02-13T10:36:00.000Z",
                        "lastUpdatedBy": "0",
                        "lastUpdated": "2023-02-13T10:36:00.000Z",
                        "title": "BOTUBS",
                        "description": "BOTUBS (Botswana Teachers Union Members Burial Society) is a  group funeral scheme underwritten by Hollard Insurance.",
                        "imageUrl": "https://mpibtu.com/wp-content/uploads/2020/10/Burial-Society.png",
                        "category": "Funeral",
                    }

                },
                "dependents": []
            }
        ]
    }
};
export default CUSTOM_CONSTANTS;