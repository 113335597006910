import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index";

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Adbox Verified',
        layout: "dashboard",
        meta: {
            layoutClass: 'layout-ga',
            title: 'Home',
        },
        component: () => import('../views/Home.vue'),
	},
	{
		path: '/product-pre-registration',
		name: 'Product Pre-Registration',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		meta: {
            layoutClass: 'layout-ga',
            title: 'Pre-Registration',
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/ProductRegistration.vue'),
	},
    {
        path: '/about-us',
        name: 'About Us',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            layoutClass: 'layout-ga',
            title: 'About Us',
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/AboutUs.vue'),
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms and Conditions',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            layoutClass: 'layout-ga',
            title: 'Terms and Conditions',
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/TermsNConditions.vue'),
    },
    {
        path: '/verification-sites',
        name: 'Verification Sites',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            layoutClass: 'layout-ga',
            title: 'Verification Sites',
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ListVerificationSites.vue'),
    },
    {
        path: '/verify/agent',
        name: 'Adbox Agent Verified',
        layout: "dashboard",
        meta: {
            layoutClass: 'layout-ga',
            title: 'Verify Agent',
        },
        component: () => import('../views/VerifyAgent.vue'),
    },
    {
        path: '/verify/promotion',
        name: 'Adbox Promo Verified',
        layout: "dashboard",
        meta: {
            layoutClass: 'layout-ga',
            title: 'Verify Promotion',
        },
        component: () => import('../views/VerifyPromotion.vue'),
    },
]



// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
});

/*router.beforeEach((to, from) => {
    window.console.log("before route loaded");
    store.dispatch("START_PROCESSING")

});*/
router.afterEach((to, from, failure) => {
	window.console.log("after route loaded");
    store.dispatch("STOP_PROCESSING");
    return false
});

export default router
