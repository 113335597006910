/**
 * Created by fulle on 2024/02/26.
 */
import http from "@/http-common";
import {itemExists, itemListExists, isNumeric} from "@/app/helpers";

export default {
    state: {
        paginated_list_sites    : null,
    },
    mutations: {
        ADD_PAGINATED_LIST_SITES(state, _value)
        {
            if(itemExists(state.paginated_list_sites))
            {
                if(typeof _value !== "undefined" && _value !== null && _value.length > 0)
                {
                    for(let i=0;i<_value.length;i++)
                        state.paginated_list_sites.push(_value[i]);
                }
                else
                {
                    state.paginated_list_sites  = null;
                }
            }
            else
            {
                state.paginated_list_sites  = _value;
            }
        },
        SET_PAGINATED_LIST_SITES(state, _value)
        {
            state.paginated_list_sites = _value;
        }
    },
    actions: {

        ADD_PAGINATED_LIST_SITES : ({commit}, _value) =>
        {
            commit("ADD_PAGINATED_LIST_SITES", _value);
        },
        SET_PAGINATED_LIST_SITES : ({commit}, _value) =>
        {
            commit("SET_PAGINATED_LIST_SITES", _value);
        },

        REQUEST_SEARCH_CODE: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `product/check`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("SEARCH CODE: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_SEARCH_AGENT: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `organisation/verify`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("SEARCH AGENT: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_SEARCH_PROMOTION: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `promotions/verify`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("SEARCH AGENT: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_LIST_VERIFICATION_SITES: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `certifier/list`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("VERIF SITE LIST: ", _response);
                        commit("STOP_PROCESSING", { root: true });


                        if(itemExists(_response.data)&&itemExists(_response.data.data))
                        {
                            if(itemExists(_formData)&&itemExists(_formData.page)&&isNumeric(_formData.page)&&_formData.page>1)
                                commit("ADD_PAGINATED_LIST_SITES", _response.data.data);
                            else
                                commit("SET_PAGINATED_LIST_SITES", _response.data.data);
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_SEARCH_VERIFICATION_SITES: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `certifier/search`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("SEARCH SITE: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_SAVE_PRODUCT: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: `product/register`,
                    data: _formData,
                    method: "POST",
                    timeout: 120000,
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    },
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        window.console.log("Response REQUEST_SAVE_PRODUCT: ", _response);
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
    },
};